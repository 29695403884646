@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@400;500&family=Bebas+Neue&family=Fira+Sans:wght@300&family=PT+Sans:ital@1&family=Roboto+Mono:wght@100;300&display=swap");

.button {
	float: left;
	border-radius: 50% !important;
	width: 50px;
	height: 50px;
	text-align: center;
	padding: 0;
	font-size: 30px;
}

/* Card style */
.group_title {
	font-family: "Barlow Condensed", sans-serif !important;
	text-align: left;
}

.padding-left-col {
	padding-right: 80px; /* Adjust the value as needed */
}

.group_card {
	margin-bottom: 30px;
	/* margin-right: 30px;  */
	font-size: 14pt;
}

/* Modals style */
.tags-input-container {
	border: 1px solid #e2e5de;
	padding: 0.5em;
	border-radius: 3px;
	width: 100%;
	margin-top: 1em;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 0.5em;
}

.tag-item {
	background-color: rgb(218, 216, 216);
	display: inline-block;
	padding: 0.5em 0.75em;
	border-radius: 20px;
}
.tag-item .close {
	height: 20px;
	width: 20px;
	background-color: rgb(48, 48, 48);
	color: #fff;
	border-radius: 50%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin-left: 0.5em;
	font-size: 18px;
	cursor: pointer;
}

.tags-input {
	flex-grow: 1;
	padding: 0.5em 0;
	border: none;
	outline: none;
}
