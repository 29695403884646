.header {
	padding-top: 20px;
	padding-bottom: 20px;
}

.password-wrapper {
	display: flex;
	align-items: center;
	position: relative;
}

.password-wrapper .form-control {
	flex-grow: 1;
}

.password-wrapper .toggle-password-btn {
	position: absolute;
	width: 60px;
	right: 15px;
	height: 90%;
	border: 1px solid #fff;
	background-color: #fff;
}

.password-wrapper .toggle-password-btn:hover {
	background-color: #fff;
}

a:hover {
	color: black !important;
}

.container {
	padding-top: 50px;
}

.signup-container {
	margin-top: 10px;
}

.google-login-container {
	display: flex;
	justify-content: center;
	margin-top: 20px; /* Adjust as needed */
}

.custom-google-login-button {
	background-color: #4285f4; /* Google blue */
	color: white;
	padding: 10px 15px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	font-size: 1rem;
	transition: background-color 0.3s;
}

.custom-google-login-button:hover {
	background-color: #357ae8;
}

.or-divider {
	display: flex;
	align-items: center;
	text-align: center;
	margin: 20px 0;
}

.or-divider::before,
.or-divider::after {
	content: "";
	flex: 1;
	border-bottom: 1px solid #d3d3d3;
}

.or-divider::before {
	margin-right: 0.5em;
}

.or-divider::after {
	margin-left: 0.5em;
}

.or-text {
	padding: 0 10px;
	color: #888; /* Optional: Change color to make "or" pop up */
	font-weight: bold;
}

/* the form css */
.centered-form {
	max-width: 450px;
	margin: 0 auto;
}

.centered-form .form-control {
	width: calc(100% - 20px);
	margin: 0 auto;
	display: block;
}

.centered-form h1,
.centered-form button {
	text-align: center;
	display: block;
	width: 100%;
}
