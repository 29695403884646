.expenses-header {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.expenses-header .expenses-week {
	display: flex;
	/* Center children horizontally in the container */
	justify-content: center;
	/* Center children vertically in the container */
	align-items: center;
	width: 100%;
}

.expenses-header span {
	margin-right: 70px;
}

.expenses-header .total-own {
	color: #0dcaf0;
}

.expenses-header .total-owe {
	color: red;
}

/* Remove margin from the last span */
.expenses-header span:last-child {
	margin-right: 0;
}
