@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@400;500&family=Bebas+Neue&family=Fira+Sans:wght@300&family=PT+Sans:ital@1&family=Roboto+Mono:wght@100;300&display=swap");

.group_card {
	margin-top: 30px;
	margin-bottom: 30px;
	margin-left: 30px;
	margin-right: 30px;
	font-size: 14pt;
	background-color: "#edcdc2" !important;
}

.group_title {
	font-family: "Barlow Condensed", sans-serif !important;
	text-align: left;
}

.row {
	padding-top: 10px;
}

.group_card_align_left {
	float: left;
	display: block;
	clear: both;
	margin-left: 29px;
}
