.header-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.web-title {
	text-align: left;
	font-size: 2em;
	margin-bottom: 20px;
	padding-left: 50px;
	padding-top: 20px;
}

.container {
	padding-top: 50px;
}

.header {
	padding-top: 20px;
	padding-bottom: 20px;
}

.signin-button {
	margin-right: 50px;
}

.password-wrapper {
	display: flex;
	align-items: center;
	position: relative;
}

.password-wrapper .form-control {
	flex-grow: 1;
}

.password-wrapper .toggle-password-btn {
	position: absolute;
	width: 60px;
	right: 15px;
	height: 90%;
	border: 1px solid #fff;
	background-color: #fff;
}

.password-wrapper .toggle-password-btn:hover {
	background-color: #fff;
}

a:hover {
	color: black !important;
}

/* the form css */
.centered-form {
	max-width: 450px;
	margin: 0 auto;
}

.centered-form .form-control {
	width: calc(100% - 20px);
	margin: 0 auto;
	display: block;
}

.centered-form h1,
.centered-form button {
	text-align: center;
	display: block;
	width: 100%;
}
